import { FieldInput, FieldLabel, Wide, WideRow } from '@newageerp/v3.bundles.form-bundle'
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle'
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useState } from 'react';

export const CustomAuthForm = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [token, setToken] = useState('');

    const doReq = async () => {
        if (!email) return;

        setError('');
        setToken('');

        setLoading(true);

        const res = await axiosInstance.post(
            '/app/Plugins/Pub/Auth/signUp',
            {
                email,
                challenge: '',
                orderId: 'test',
            }
        )

        if (!!res.data.error) {
            setError(res.data.error);
        }
        if (!!res.data.token) {
            setToken(res.data.token);
        }

        setLoading(false);
    }

    return (
        <WhiteCard>
            <Wide>
                <WideRow
                    control={<FieldInput value={email} onChange={e => setEmail(e.target.value)} className='w-[300px]' />}
                    label={<FieldLabel>Email</FieldLabel>}
                />
            </Wide>
            <MainButton onClick={doReq} iconName='sign-in' loading={loading}>Register</MainButton>

            {!!error && <p className='text-red-500'>{error}</p>}

            {!!token && <pre>https://app.todayistheday.app/signup/{token}</pre>}
        </WhiteCard>
    )
}