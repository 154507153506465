import { FieldInput, FieldLabel, Wide, WideRow } from '@newageerp/v3.bundles.form-bundle'
import React, { useState } from 'react'
import { MainButton } from '@newageerp/v3.bundles.buttons-bundle'

export default function MigrationForm() {
    const [email, setEmail] = useState('');

    const openKlaviyoLink = () => {
        window.open(
            `/app/Plugins/Pub/Migration/do-with-kl/${email}`,
            '_blank'
        )
    }

    const openDataLink = () => {
        window.open(
            `/app/Plugins/Pub/Migration/do/${email}`,
            '_blank'
        )
    }

    return (
        <div>
            <Wide>
                <WideRow
                    label={<FieldLabel>Email</FieldLabel>}
                    control={<FieldInput
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    }
                />
                <div className='flex gap-2'>
                    <MainButton color='blue' onClick={openKlaviyoLink}>
                        Migrate with klaviyo / adalo update
                    </MainButton>
                    <MainButton color='sky' onClick={openDataLink}>
                        Migrate only data
                    </MainButton>
                </div>
            </Wide>
        </div>
    )
}
