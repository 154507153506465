import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "Achievement",
        "schema": "achievement",
        "title": "Achievement",
        "titlePlural": "Achievements",
        "required": [],
        "scopes": []
    },
    {
        "className": "AppUser",
        "schema": "app-user",
        "title": "App user",
        "titlePlural": "App users",
        "required": [],
        "scopes": []
    },
    {
        "className": "AppUserActivity",
        "schema": "app-user-activity",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "AppUserRelationship",
        "schema": "app-user-relationship",
        "title": "User relationship",
        "titlePlural": "User relationships",
        "required": [],
        "scopes": []
    },
    {
        "className": "Article",
        "schema": "article",
        "title": "Article",
        "titlePlural": "Articles",
        "required": [],
        "scopes": []
    },
    {
        "className": "ArticleUser",
        "schema": "article-user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Challenge",
        "schema": "challenge",
        "title": "Challenge",
        "titlePlural": "Challenges",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChallengeLesson",
        "schema": "challenge-lesson",
        "title": "Lesson",
        "titlePlural": "Lessons",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChallengeUser",
        "schema": "challenge-user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChallengeUserAchievement",
        "schema": "challenge-user-achievement",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChallengeUserLesson",
        "schema": "challenge-user-lesson",
        "title": "User lesson",
        "titlePlural": "User lessons",
        "required": [],
        "scopes": []
    },
    {
        "className": "ChallengeUserLessonNote",
        "schema": "challenge-user-lesson-note",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "DiaryNote",
        "schema": "diary-note",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "Tip",
        "schema": "tip",
        "title": "Tip",
        "titlePlural": "Tips",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "AppUser": {
        "className": "AppUser",
        "schema": "app-user"
    },
    "Tip": {
        "className": "Tip",
        "schema": "tip"
    },
    "Article": {
        "className": "Article",
        "schema": "article"
    },
    "ChallengeUserLesson": {
        "className": "ChallengeUserLesson",
        "schema": "challenge-user-lesson"
    },
    "AppUserRelationship": {
        "className": "AppUserRelationship",
        "schema": "app-user-relationship"
    },
    "ChallengeUserLessonNote": {
        "className": "ChallengeUserLessonNote",
        "schema": "challenge-user-lesson-note"
    },
    "Challenge": {
        "className": "Challenge",
        "schema": "challenge"
    },
    "ChallengeLesson": {
        "className": "ChallengeLesson",
        "schema": "challenge-lesson"
    },
    "ChallengeUser": {
        "className": "ChallengeUser",
        "schema": "challenge-user"
    },
    "DiaryNote": {
        "className": "DiaryNote",
        "schema": "diary-note"
    },
    "Achievement": {
        "className": "Achievement",
        "schema": "achievement"
    },
    "ChallengeUserAchievement": {
        "className": "ChallengeUserAchievement",
        "schema": "challenge-user-achievement"
    },
    "ArticleUser": {
        "className": "ArticleUser",
        "schema": "article-user"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    },
    "AppUserActivity": {
        "className": "AppUserActivity",
        "schema": "app-user-activity"
    }
}