import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CustomAuthForm } from "./CustomAuth/CustomAuthForm";
import MigrationForm from "./Migration/MigrationForm";
import UsersMenuItem from "./Users/UsersMenuItem";

export const PluginsMap: any = {
    ...CustomViewComponentsMap,
    ...CustomEditComponentsMap,
    ...CustomListComponentsMap,

    'custom.user.genToken': UsersMenuItem,
    
    'custom.routes': {
        '/c/custom-sign-up': CustomAuthForm,
        '/c/custom-migrate': MigrationForm,
    }
}